export const CashBankCoa = {
  ACC_CASH: 110100,
  ACC_BANK: 110200,
}

export const Coa = {
  CASH_BOTTOM_BOUND: 110100,
  PETTY_CASH: 110102,
  CASH_UPPER_BOUND: 110200,

  BANK_BOTTOM_BOUND: 110200,
  BANK_UPPER_BOUND: 110300,

  INVESTATION_BOTTOM_BOUND: 110300,
  INVESTATION_UPPER_BOUND: 110500,

  ACC_RECEIVABLE: 110500,
  ACC_STOCK: 110800,
  // TODO might as well define bounds for other accounts
  ASSET_BOTTOM_BOUND: 110000,
  ASSET_UPPER_BOUND: 200000,

  LIABILITY_BOTTOM_BOUND: 210000,
  LIABILITY_UPPER_BOUND: 300000,

  EQUITY_BOTTOM_BOUND: 310000,
  EQUITY_UPPER_BOUND: 400000,

  INCOME_BOTTOM_BOUND: 400000,
  INCOME_UPPER_BOUND: 500000,

  OPERATIONAL_INCOME_BOTTOM_BOUND: 400000,
  OPERATIONAL_INCOME_UPPER_BOUND: 420000,

  NON_OPERATIONAL_INCOME_BOTTOM_BOUND: 500000,
  NON_OPERATIONAL_INCOME_UPPER_BOUND: 520000,

  EXPENSE_BOTTOM_BOUND: 500000,
  EXPENSE_UPPER_BOUND: 600000,

  OPERATIONAL_EXPENSE_BOTTOM_BOUND: 420000,
  OPERATIONAL_EXPENSE_UPPER_BOUND: 500000,

  NON_OPERATIONAL_EXPENSE_BOTTOM_BOUND: 520000,
  NON_OPERATIONAL_EXPENSE_UPPER_BOUND: 600000,

  FIXED_ASSET_BOTTOM_BOUND: 120000,
  FIXED_ASSET_UPPER_BOUND: 130000,

  SAVING_BOTTOM_BOUND: 210200,
  SAVING_UPPER_BOUND: 210300,
  VOLUNTEER_SAVINGS: 210201,
  HOLYDAY_SAVINGS: 210202,
  EDUCATION_SAVINGS: 210203,

  TERM_SAVING_BOTTOM_BOUND: 210300,
  TERM_SAVING_UPPER_BOUND: 210400,

  LIABILITY: 220100,

  //NON_TRADE_PAYABLE_BOTTOM_BOUND: 211100,

  //NON_TRADE_PAYABLE_UPPER_BOUND: 230000,

  MEMBER_CAPITAL_BOTTOM_BOUND: 310000,
  MEMBER_CAPITAL_UPPER_BOUND: 310300,
  PRINCIPAL_SAVINGS: 310100,
  MANDATORY_SAVINGS: 310200,
  INCOME_STATEMENT_ACCUMULATION: 330000,

  ACC_NET_PROFIT: 550000,

  // TODO might as well define bounds for other accounts

  // COA Codes
  // Asset
  ACC_GRUP_AKTIVA: 100000,
  ACC_GRUP_ACTIVA_LANCAR: 110000,
  ACC_GRUP_ACTIVA_TETAP: 120000,
  ACC_GRUP_ASSET_LAIN_LAIN: 130000,

  //cash group
  ACC_ASET_LANCAR: 110000,
  ACC_KAS: 110100,
  ACC_KAS_BESAR: 110101,
  ACC_KAS_KECIL: 110102,
  ACC_BANK: 110200,
  ACC_SURAT_BERHARGA: 110300,
  ACC_GRUP_PINJAMAN: 110400,
  ACC_PINJAMAN_MUDHARABAH: 110410,
  ACC_PINJAMAN_MUSYARAKAH: 110420,
  ACC_PINJAMAN_MURABAHAH: 110430,
  ACC_PINJAMAN_IJARAH: 110440,
  ACC_PINJAMAN_KAFALAH: 110450,
  ACC_PINJAMAN_WAKALAH: 110460,
  ACC_PINJAMAN_HAWALAH: 110470,
  ACC_PINJAMAN_QARDH: 110480,
  ACC_SIMPANAN_PINJAMAN: 110500,
  ACC_PENYISIHAN_PINJAMAN: 110600,
  ACC_PERLENGKAPAN: 110700,
  ACC_PAJAK_DIBAYAR_DI_MUKA_PPH_PASAL_25: 110800,
  ACC_BIAYA_DIBAYAR_DI_MUKA: 110900,
  ACC_PENDAPATAN_MASIH_HARUS_DITERIMA: 111000,
  ACC_ASET_LANCAR_LAIN: 111100,
  ACC_GRUP_ASET_TIDAK_LANCAR: 120000,
  ACC_GRUP_INVESTASI_JANGKA_PANJANG: 120100,

  ACC_PENYUSUTAN_AWAL: 120200, //begin adjusted acc
  ACC_PROPERTI_INVESTASI: 120200,
  ACC_AKM_PENYUSUTAN_PROPERTI_INVESTASI: 120300,
  ACC_GRUP_AKTIVA_TETAP: 120400,
  ACC_TANAH_HAK_ATAS_TANAH: 120410,
  ACC_AKM_PENYUSUTAN_TANAH_HAK_ATAS_TANAH: 120411,
  ACC_BANGUNAN: 120420,
  ACC_AKM_PENYUSUTAN_BANGUNAN: 120421,
  ACC_MESIN_DAN_KENDARAAN: 120430,
  ACC_AKM_PENYUSUTAN_MESIN_DAN_KENDARAAN: 120431,
  ACC_INVENTARIS_DAN_PERALATAN_KANTOR: 120440,
  ACC_AKM_PENYUSUTAN_INVENTARIS_DAN_PERALATAN_KANTOR: 120441,
  ACC_ASET_TIDAK_BERWUJUD: 120450,
  ACC_AKM_AMORTISASI_ASET_TIDAK_BERWUJUD: 120451,
  ACC_GRUP_ASET_TIDAK_LANCAR_LAIN: 130000,

  //liability group
  ACC_GRUP_KEWAJIBAN: 200000,
  ACC_GRUP_KEWAJIBAN_LANCAR: 210000,
  ACC_GRUP_SIMPANAN_KOPERASI: 210100,
  ACC_SIMPANAN_MUDHARABAH: 210101,
  ACC_SIMPANAN_KHUSUS: 210102,
  ACC_SIMPANAN_COLLATERAL: 210103,
  ACC_SIMPANAN_WADIAH: 210104,
  ACC_GRUP_SIMPANAN_BERJANGKA: 210200,
  ACC_SIMPANAN_BERJANGKA_1_BULAN: 210201,
  ACC_SIMPANAN_BERJANGKA_2_BULAN: 210202,
  ACC_SIMPANAN_BERJANGKA_3_BULAN: 210203,
  ACC_SIMPANAN_BERJANGKA_4_BULAN: 210204,
  ACC_SIMPANAN_BERJANGKA_5_BULAN: 210205,
  ACC_SIMPANAN_BERJANGKA_6_BULAN: 210206,
  ACC_SIMPANAN_BERJANGKA_7_BULAN: 210207,
  ACC_SIMPANAN_BERJANGKA_8_BULAN: 210208,
  ACC_SIMPANAN_BERJANGKA_9_BULAN: 210209,
  ACC_SIMPANAN_BERJANGKA_10_BULAN: 210210,
  ACC_SIMPANAN_BERJANGKA_11_BULAN: 210211,
  ACC_SIMPANAN_BERJANGKA_12_BULAN: 210212,
  ACC_HUTANG_PPH_FINAL: 210300,
  ACC_HUTANG_PPN: 210400,
  ACC_HUTANG_ZAKAT: 210500,
  ACC_HUTANG_INFAQ_SHODAQOH: 210600,
  ACC_DANA_PENDIDIKAN: 210700,
  ACC_DANA_SOSIAL: 210800,
  ACC_DANA_PENGURUS: 210900,
  ACC_DANA_KARYAWAN: 211000,
  ACC_HUTANG_NON_DAGANG: 211100,
  ACC_KEWAJIBAN_JANGKA_PANJANG: 220000,
  ACC_HUTANG_PIHAK_KETIGA: 220100,
  ACC_GRUP_KEWAJIBAN_LAIN_LAIN: 230000,

  //equity group
  ACC_GRUP_MODAL: 300000,
  ACC_MODAL_SENDIRI: 310000,
  ACC_SIMPANAN_POKOK: 310100,
  ACC_SIMPANAN_WAJIB: 310200,
  ACC_MODAL_PENYERTAAN: 310300,
  ACC_SIMKHUS: 310400,
  ACC_MODAL_HIBAH: 320000,
  ACC_DANA_CADANGAN: 330000,
  ACC_SHU_TIDAK_DIBAGI: 340000,
  ACC_AKM_LABA_RUGI: 350000,

  //operational incomes and expenses group
  ACC_GRUP_PENDAPATAN_DAN_BEBAN_OPERASIONAL: 410000,
  ACC_PENDAPATAN_JASA_PINJAMAN: 410100,
  ACC_GRUP_BIAYA_OPERASIONAL: 420000,
  ACC_GRUP_BEBAN_USAHA: 420100,
  ACC_BIAYA_JASA_SIMPANAN_KOPERASI: 420101,
  ACC_BIAYA_JASA_SIMPANAN_BERJANGKA: 420102,
  ACC_BIAYA_PENYISIHAN_PINJAMAN_TAK_TERTAGIH: 420103,
  ACC_BIAYA_GAJI_KARYAWAN: 420104,
  ACC_BIAYA_HONOR_PENGURUS_PENGAWAS: 420105,
  ACC_BIAYA_BPJS_KESEHATAN: 420106,
  ACC_BIAYA_BPJS_KETENAGAKERJAAN: 420107,
  ACC_BIAYA_ATK: 420108,
  ACC_BIAYA_LISTRIK_INTERNET: 420109,
  ACC_BIAYA_KONSUMSI: 420110,
  ACC_BIAYA_THR: 420111,
  ACC_BIAYA_TRANSPORT: 420112,
  ACC_BIAYA_RAT: 420113,
  ACC_BIAYA_JASA_MODAL_PENYERTAAN: 420114,
  ACC_BIAYA_OPERASIONAL_LAIN: 420115,

  ACC_GRUP_BIAYA_ORGANISASI: 420200,

  ACC_GRUP_BIAYA_PENYUSUTAN: 420300,
  ACC_BIAYA_PENYUSUTAN_PROPERTI_INVESTASI: 420301,
  ACC_BIAYA_PENYUSUTAN_TANAH_HAK_ATAS_TANAH: 420302,
  ACC_BIAYA_PENYUSUTAN_BANGUNAN: 420303,
  ACC_BIAYA_PENYUSUTAN_MESIN_DAN_KENDARAAN: 420304,
  ACC_BIAYA_PENYUSUTAN_INVENTARIS_DAN_PERALATAN_KANTOR: 420305,
  ACC_BIAYA_AMORTISASI_ASET_TIDAK_BERWUJUD: 420306,

  ACC_GRUP_BIAYA_PERAWATAN: 420400,
  ACC_BIAYA_PERAWATAN_INVENTARIS_DAN_PERALATAN_KANTOR: 420401,

  //non operational incomes and expenses group
  ACC_GRUP_PENDAPATAN_DAN_BIAYA_NON_OPERASIONAL: 500000,
  ACC_GRUP_PENDAPATAN_NON_OPERASIONAL: 510000,
  ACC_PENDAPATAN_BAGI_HASIL_BANK: 510100,
  ACC_GRUP_BIAYA_NON_OPERASIONAL: 520000,
  ACC_BIAYA_RTGS: 520100,
  ACC_BIAYA_ADM_SMS_BANK: 520200,
  ACC_GROUP_BIAYA_PAJAK: 520300,
  ACC_BIAYA_PPH_FINAL_BAGI_HASIL_BANK: 520301,
  ACC_BIAYA_PPH_PASAL_4: 520302,
  ACC_BIAYA_ZAKAT: 520303,
  ACC_BIAYA_INFAQ_SHODAQOH: 520304,
  ACC_BIAYA_BUNGA_KEWAJIBAN: 520500,
  ACC_LABA_RUGI_PENJUALAN_ASET: 520600,
  ACC_LABA_RUGI_REVALUASI_ASET: 520700,
  ACC_LABA_RUGI: 530000,

  //fake accoun,just for help reporting
  BALANCE_SHEET_SUMMARY: 399000,
  TOTAL_OPERATIONAL_INCOME: 419990,
  TOTAL_OPERATIONAL_EXPENSES: 429900,
  INCOME_AFTER_OPERATIONAL_EXPENSES: 429991,
  TOTAL_OTHER_INCOME_AND_EXPENSES: 519990,
  PROFIT_BEFORE_TAX: 570499,
  CORPORATE_TAX_PAYABLE: 570500,
  PROFIT_AFTER_TAX: 580000,
  TOTAL_SUMMARY: 699999,
}

export const Gender = {
  Male: 0,
  Female: 1,
}

export const MaritalStatus = {
  Single: 0,
  Married: 1,
  Divorced: 2,
}

export const UserStatus = {
  Pending: 0,
  Activated: 1,
  Deactivated: 2,
  Deleted: 3,
}

export const ProductType = {
  Product: 1,
  DirectMaterial: 2,
}

export const ProductionType = {
  NEW: 1,
  REPACK: 2,
  REPAIR: 3,
}

export const ProductSaleOrderStatus = {
  PENDING: 1,
  PAID: 2,
  PRINT: 3,
  SEND: 4,
  OK: 5,
}

export const BankTransaction = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
  ADMINISTRATIVE_COST: 3,
  INTEREST: 4,
  TRANSFER: 5,
}

export const CodeMarketerDept = 'DPT0000005'

export const UserStatusLabel = {
  [UserStatus.Pending]: 'Pending',
  [UserStatus.Activated]: 'Activated',
  [UserStatus.Deactivated]: 'Deactivated',
  [UserStatus.Deleted]: 'Deleted',
}

export const UserStatusColor = {
  [UserStatus.Pending]: 'warning',
  [UserStatus.Activated]: 'success',
  [UserStatus.Deactivated]: 'danger',
  [UserStatus.Deleted]: 'danger',
}

export const GrantCapitalStatus = {
  Pending: 0,
  Posted: 1,
}

export const GrantCapitalStatusLabel = {
  [GrantCapitalStatus.Pending]: 'Pending',
  [GrantCapitalStatus.Posted]: 'Posted',
}

export const GrantCapitalStatusColor = {
  [GrantCapitalStatus.Pending]: 'warning',
  [GrantCapitalStatus.Posted]: 'success',
}

export const TermSavingStatus = {
  Pending: 0,
  Posted: 1,
  Closed: 2,
  Blocked: 3,
}

export const TermSavingStatusLabel = {
  [TermSavingStatus.Pending]: 'Pending',
  [TermSavingStatus.Posted]: 'Active',
  [TermSavingStatus.Closed]: 'Closed',
  [TermSavingStatus.Blocked]: 'Bloked',
}

export const TermSavingStatusColor = {
  [TermSavingStatus.Pending]: 'warning',
  [TermSavingStatus.Posted]: 'success',
  [TermSavingStatus.Closed]: 'danger',
  [TermSavingStatus.Blocked]: 'danger',
}

export const SavingStatus = {
  Active: 0,
  Closed: 1,
  Blocked: 2,
}

export const SavingStatusLabel = {
  [SavingStatus.Active]: 'Active',
  [SavingStatus.Closed]: 'Closed',
  [SavingStatus.Blocked]: 'Blocked',
}

export const SavingStatusColor = {
  [SavingStatus.Active]: 'success',
  [SavingStatus.Closed]: 'secondary',
  [SavingStatus.Blocked]: 'danger',
}

export const SavingTransaction = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
  SAVING_INTEREST: 3,
  ADMINISTRATIVE_COST: 4,
  CLOSING: 5,
  CLOSING_COST: 6,
  TERM_SAVING_INTEREST: 33,
}

export const LendingStatus = {
  Pending: 0,
  Approved: 1,
  Disbursed: 2,
  Fulfilled: 3,
  Rejected: 4,
}

export const LendingStatusLabel = {
  [LendingStatus.Pending]: 'Pengajuan',
  [LendingStatus.Approved]: 'Disetujui',
  [LendingStatus.Disbursed]: 'Dicairkan',
  [LendingStatus.Fulfilled]: 'Lunas',
  [LendingStatus.Rejected]: 'Ditolak',
}

export const LendingStatusColor = {
  [LendingStatus.Pending]: 'warning',
  [LendingStatus.Approved]: 'success',
  [LendingStatus.Disbursed]: 'success',
  [LendingStatus.Fulfilled]: 'secondary',
  [LendingStatus.Rejected]: 'danger',
}

export const DeptCode = {
  CODE_DEPT_PENGAWAS: 'DPT0000001',
  CODE_DEPT_PENGURUS: 'DPT0000002',
  CODE_DEPT_MANAJER: 'DPT0000003',
  CODE_DEPT_KEUANGAN: 'DPT0000004',
  CODE_DEPT_PEMBELIAN: 'DPT0000005',
  CODE_DEPT_PEMASARAN: 'DPT0000006',
  CODE_DEPT_PERSONALIA: 'DPT0000007',
}

export const Style = {
  fontSize: 14,
  size: 'sm',
}

export const AppSetting = {
  GENERAL: 3,
  PRODUCT_PURCHASE: 1,
  PRODUCT_SALE: 2,

  //sales
  DIRECT_SALE: 21,
  USE_TAX: 22,
  USE_FARM_TAX: 23,
  SALES_OVER_PAY_INTO_ACCOUNT_RECEIVABLE: 24,

  //purchase
  DIRECT_PURCHASE: 11,
  TAX_INTO_CGS: 12,
  SHIPPING_COST_INTO_CGS: 13,
  PURCHASE_OVER_PAY_INTO_DEBT: 14,

  //general
  SUPPLIER_BALANCE_CHECK: 31,
  CUSTOMER_BALANCE_CHECK: 32,
  USE_POINT: 33,
  DROPSHIP: 34,
  BEGINNING_BALANCE: 35,
  BACK_DATE: 36,
}

export const LendingCategoryFeeType = {
  NON_PROFIT: 0,
  PROFIT_SHARING: 1,
  MARGIN: 2,
  FEE: 3,
}

export const LendingInstallmentType = {
  DAILY: 0,
  WEEKLY: 1,
  BIWEEKLY: 2,
  MONTHLY: 3,
  DUE_DATE: 4,
}

export const BeginningBalance = {
  BB_FLAG: 3,
}

export const GroupUserType = {
  GROUP_TRUSTIE_TYPE: 1,
  GROUP_EMPLOYEE_TYPE: 2,
  GROUP_MEMBER_TYPE: 3,
}

export const GroupIndex = {
  SUPERUSER_IDX: 1,
  ADMIN_IDX: 2,
  TRUSTIE_IDX: 3,
  MEMBER_IDX: 4,
  CASHIER_IDX: 5,
  MARKETING_IDX: 6,
}

export const UserType = {
  USER_TRUSTIE_TYPE: 1,
  USER_EMPLOYEE_TYPE: 2,
  USER_MEMBER_TYPE: 3,
}

export const DeptGroup = {
  DEPT_TRUSTIE_GROUP: 1,
  DEPT_EMPLOYEE_GROUP: 2,
}

export const TermSavingTransaction = {
  DISBURSEMENT: 0,
  PROFIT: 1,
  REVERT_PROFIT: 2,
  CLOSING: 3,
}

export const LendingInstallment = {
  DISBURSEMENT: 0,
  INSTALLMENT: 1,
  REVERT_INSTALLMENT: 2,
}

export const CollateralCategory = {
  BPKB: 1,
  SHM: 2,
  BARANG_BERHARGA: 3,
  TUNJUK: 4,
}

export const CollateralStatus = {
  PENDING: 0,
  ACCEPTED: 1,
  WITHDRAWN: 2,
  REJECTED: 3,
}

export const FlagTransactionTemplate = {
  FLAG_PENDING: 0,
  FLAG_POSTED: 1,
}

export const LiabilityTransactionCode = {
  INITIAL_TRANSACTION: 0,
  INSTALLMENT_TRANSACTION: 1,
  REVERT_TRANSACTION: 2,
}

export const LiabilityTransactionOrigin = {
  PRINCIPAL_ORIGIN: 0,
  FEE_ORIGIN: 1,
}

export const ParticipationCapitalIsMember = {
  MEMBER: 0,
  NON_MEMBER: 1,
}
